@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');


body{
  padding:0;
  margin:0;
  font-family: 'Work Sans';
  overflow-x: hidden;
}

.container-fluid{
  padding:0;
  margin:0;
}

.forBg{
  background-image: url('https://finhealthnetwork.org/wp-content/uploads/2022/04/Smiling-young-african-american-professional-businessman-and-businesswoman-1200x628.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}



::selection
{
  background-color: rgb(69, 165, 255);
  color: white;
}

.navbar{
  height: 120px !important;
  background: #fff !important;
  color: black;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.navbar-brand{
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 10px;
  color: red;
}

.nav-link{
  font-weight: 400;
  font-size: large;
  margin-right: 15px;
  color: black !important;
}

.logo{
  width: 130px !important;
  margin-left: 100px;
}

.start-text{
  font-size: 53px !important;
  font-weight: 700 !important;
  margin-bottom: 50px !important;
}

.para{
  margin-top: 20px !important;
  margin-bottom: 40px !important;
  font-weight: 400 !important;
}

.codingwiztext{
  text-shadow: none;
}

.hero-btn{
  padding: 12px 30px !important;
  font-weight: 600 !important;
  font-size: 16.5px !important;
}

.hero-btn2{
  padding: 12px 30px !important;
  font-weight: 600 !important;
  font-size: 16.5px !important;
  background-color: rgba(255, 255, 255, 1) !important;
}

.call-btn{
  height: 45px;
  margin-right: 10px;
  width: 100px !important;
  transition-duration: 150ms;
  background-color: #4ac6d6 !important;
  color: white !important;
}

.call-anchor{
  color: white !important;
  font-weight: 600;
}

.call-btn:hover{
  width: 110px !important;
}

/* Start of CSS FOR NAVBAR */

@media (min-width: 991px) {
  .logo {
    width: 3%;
    /* margin-left:25px; */
  }
}

@media (max-width: 990px) {
  .logo {
    width: 8%;
  }
}

@media (max-width: 399px) {
  .logo {
    width: 13%;
  }
}

@media (max-width:500px) {
  .logo {
    width: 11%;
  }
}

.navbar
{
  background-color: rgb(69, 165, 255);
  cursor: pointer;
}

.early-bird-text
{
  text-align: center;
  /* color:rgb(69, 165, 255); */
  /* color:transparent; */
  /* -webkit-background-clip:text; */
  /* background-color: #FFE53B; */
/* background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%); */
font-weight: bold;
font-size: 18px;
background-color: rgb(69, 165, 255);
color: white;
}

@media (max-width:576px)
{
  .early-bird-text
  {
    font-size: 15px;
  }
}

.call-btn
{
  padding:0px 10px !important;
  border:none;
  border-radius: 7px;
  background-color: white;
  color:rgb(69, 165, 255);
  cursor: pointer;
  width: 100px;
  transition-duration: 150ms;
  margin-right: 20px !important;
}

.call-btn:hover{
  width: 150px;
}

@media (max-width:991px)
{
  .call-btn{
    width:40%;
    margin-left:0px !important;
    padding:2px 0px !important;
    margin-top: 10px !important;
  }
}

.link
{
  text-decoration: none;
}

.call-anchor
{
  text-decoration: none;
  color:rgb(69, 165, 255);
}

/* End of CSS FOR NAVBAR */

/* Start of CSS FOR Hero */

.forBg{
  background-image: url('https://finhealthnetwork.org/wp-content/uploads/2022/04/Smiling-young-african-american-professional-businessman-and-businesswoman-1200x628.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.hero-row
{
  background-color: rgba(0, 0, 0, 0.6);
  color:white;
}

@media (min-width:991px)
{
  .hero-row
  {
   height:90vh;
  }
}


.hero-row p{
  font-size: 20px;
  text-align: justify;
  font-weight: lighter;
}

.hero-btn
{
  padding:8px 20px;
  border:none;
  background-color: rgb(69, 165, 255);
  color:white;
  cursor: pointer;
  margin:10px 20px 10px 0px;
  border-radius: 8px;
  width: 200px;
  transition-duration: 150ms;
}

.hero-btn2
{
  padding:8px 20px;
  width: max-content;
  width: 200px;
  /* border:1px solid rgb(0, 132, 255); */
  border: 0;
  background-color: transparent;
  color:rgb(69, 165, 255);
  cursor: pointer;
  margin:10px 20px 10px 0px;
  border-radius: 8px;
  transition-duration: 150ms;
}

.hero-btn:hover{
  width: 210px;
}

.hero-btn2:hover{
  border:none;
  width: 210px;
  /* transform: scale(0.9); */
  /* transition: 0.4s ease-in-out; */
}

.hero-row img{
  width:100% !important;
  height:100% !important;
}

.callTutors
{
  color:rgb(69, 165, 255);
  text-decoration: none;
}

.callTutors:hover 
{
  color:white;
}

.codingwiztext{
  color:transparent;
  -webkit-background-clip:text;
  background-clip: text;
  background-color: #4ac6d6;
  /* background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%); */
  transition: 0.2s ease-in-out;
}

.start-text
{
  font-size: 47px;
  font-weight: 900;
}

@media (max-width:767px)
{
  .start-text
  {
    font-size: 40px;
  }
}

/* End of CSS FOR Hero */

/* Start of Why us CSS */
.yus-card
{
  border:1px solid rgb(219, 216, 216);
  border-radius: 12px;
  cursor:pointer;
}

.yus-card:hover
{
  -webkit-box-shadow:0px 0px 10px grey;
  box-shadow:0px 0px 10px grey;
  transition:0.4s ease-in-out;
  transform: scale(1.02);
}

/* ENd of Why us CSS */

/* Start of Course CSS */

.course-card
{
  border:1px solid rgb(219, 216, 216);
  border-radius: 12px;
  cursor:pointer;
  padding:10px;
  margin:12px;
}

.parent-img
{
  width:100%;
  height:430px;
  height: fit-content;
  margin-bottom: 15px;
}

.course-img
{
  border-radius: 12px;
  margin-bottom:16px;
  height: 100%;
  width:100%;
  object-fit: cover;
}


.course-card p{
  margin:8px 0px;
}

.course-btn
{
  width:100%;
  padding: 7px 20px;
  text-align: center;
  background-color: rgb(69, 165, 255);
  border: none;
  border-radius: 10px;
  cursor:pointer;
  color:white;
}

.bestselling-btn:first-child
{
  color:rgb(242, 242, 242);
  background-color: red;
}

/* End of Course CSS */

/* Start of footer css */
.footer
{
  background-color: rgb(34, 111, 184);
  color:white;
}

.footer p{
  cursor: pointer;
  font-weight: 300;
}

.social-media a{
  text-decoration: none;
  color:white;
}

/* End of footer css */

/* Start of Technology CSS */
.js
{
  width:100%;
  object-fit: cover;
  margin-bottom: 10px;
}
.react
{
  width:90%;
  margin:30px 10px 20px;
}
.express
{
  width:85%;
  margin:25px 10px 10px;
}
.nodejs
{
  width:85%;
  margin:25px 0px 10px;
}
.html
{
  width:81%;
  margin:29px 0px 12px;
}
.css
{
  width:85%;
  margin:10px;
}

.bootstrap
{
  width:100%;
  margin:12px 5px 13px 3px;
}

.mongodb
{
  width:80%;
  margin:10px;
}

.t-card
{
  border:1px solid rgb(216, 214, 214);
  border-radius: 12px;
  margin:10px;
}

.t-card:hover{
  box-shadow:0px 0px 10px grey;
  -webkit-box-shadow:0px 0px 10px grey;
  transition: 0.5s ease-in-out;
  transform: scale(1.01);
  cursor: pointer;
}

/* End of Technology CSS */

/* Start of Contact CSS */

.contact-row
{
  background-color:black;
}

.contact-row h4{
  font-weight: bolder; 
}

.contact-btn
{
  padding:8px 10px;
  width:100%;
  border-radius: 10px;
  border:none;
  background-color: rgb(69, 165, 255);
  color:white;
  cursor: pointer;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 0rem 0.75rem;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(2.5rem + 2px);
  line-height: 1;
}

.form-floating>label {
  padding: 0.5rem 0.75rem;
}

.contact-form input:focus , .contact-form textarea:focus{
  outline: none;
  border:1px solid rgb(69, 165, 255);
}

.contact-form{
  border:1px solid rgb(212, 210, 210);
  border-radius: 8px;
  padding:10px;
}

.contact-form h5{
  color:rgb(69, 165, 255);
}

.contact-box
{
  border:1px solid rgb(222, 220, 220);
  border-radius: 8px;
}

.contact-box p{
  margin-bottom:8px;
}

@media (max-width:767px)
{
  .contact-hide-div
  {
    display: none;
  }
}

@media (min-width:768px)
{
  .contact-hide-div2
  {
    display: none;
  }
}

.map
{
  width:100%;
  /* height:200%; */
  height:250px;
}

@media (min-width:991px)
{
  .map{
    height:400px;
  }
}


/* End of Contact CSS */

/* CSS Start for CourseDetail Page */

.star
{
  color:rgb(255, 208, 0);
}

@media (min-width:576px)
{
  .relative
{
  position: relative;
}

.absolute
{
  position: absolute;
  top:30px;
  right:100px;
}
}

.course-card2
{
  margin: 10px 0px !important;
}

.course-detail-main-row
{
  background-color: black;
}

.card-detail-row
{
  margin:0px !important;
}

.money-text
{
  font-size: 12px;
  line-height: 16px;
}

.text-499
{
  font-weight: bold;
  font-size: 20px;
}

.course-include-card
{
  padding:24px 40px;
}

@media (max-width:767px)
{
  .course-include-card
  {
    padding:24px 10px 1px;
  }

  .course-include-card2
  {
    padding:5px 10px 10px !important;
  }
}

.course-include-card2
{
  padding:10px 40px;
}

.course-detail-list li
{
  word-spacing:3px;
  line-height: 30px;
  font-size: 16px;
  text-align: justify;
}

.course-detail-desc
{
  text-align: justify;
}


/* CSS end for CourseDetail Page */


/* Start of privacy css */
.privacy-row
{
  text-align: justify;
}

/* end of privacy css */

/* Structure CSS Start */
.show-detail
{
  margin:20px auto;
  padding:10px 20px;
  border: 1px solid rgb(69, 165, 255);
  width:30vw;
  color:rgb(0, 0, 0);
  cursor: pointer;
}

@media only screen and (max-width:467px)
{
  .show-detail{
  padding:10px 10px !important;
  width:90vw;
  }

  .call-anchor{
    display: none;
  }

  .intern-cat-container{
    /* margin: auto 10px; */
    position: relative !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  .intern-cat-cards {
    margin-top: 1rem !important;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    margin-left: 0 !important;
  }
  
  .imgCard{
    margin: 1rem 0 !important;
  }

  .imgCard span{
    color:rgb(0, 0, 0) !important;
  }


  /* .intern-cat-cards{
    display: grid;
    grid-template-columns: 0.8fr 0.8fr !important;
    padding:10px 30px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    max-width: 100vw !important;
  } */

  /* .imgCard{
    margin-left: 0 !important;
    margin: 5px 0.8rem !important;
    margin-right: 0 !important;
  } */

  .container {
    width: 100vw !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: left !important;
    position: relative !important;
    top: 0 !important;
  }

  .filters{
    position: relative !important;
    width: 100% !important;
  }

  .internship-list{
    /* width: 100 !important; */
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    position: relative !important;
    left: 0 !important;
    margin-left: 0 !important;
    margin-top: 25px !important;
    justify-self: center !important;
    align-self: flex-start !important;
  }

  .card{
    width: 100% !important;
    justify-self: left !important;
    align-self: flex-start !important;
    margin-left: 0 !important;
    flex-grow: 1 !important;
    position: relative !important;
    line-height: 20px !important;
    left: 0 !important;
  }

}

.structure-display
{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.show-detail p{
   font-size: 18px;
}

.x-schedule
{
  text-align: center;
  font-size:5vh;
  color:rgb(69, 165, 255);
  margin-top:5vh;
}

/* animation css laoding */
.center {
  height: 58vh;
  display: flex;
  justify-content: center;
  margin-top:20vh;
  /* align-items: center; */
  background: white;
}

.d-none
{
  display: none;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, rgb(69, 165, 255));
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
 /* animation end */

 /* Structure CSS END */

 /* Testomonial CSS Start */

 .test-card
{
    border:1px solid rgb(202, 201, 201);
    border-radius:8px
}


.student-img
{
    border-radius:50%;
    object-fit: cover;
    margin:0px 10px 12px;
    align-items: center;
}

.studentname-para
{
  font-weight: bold;
  font-size: 14px;
}

.studentname-para span{
  font-weight: lighter;
  font-size: 12px;
}

.insta-testimonial-icon
{
  color:rgb(69, 165, 255);
  margin-right:12px;
  cursor: pointer;
}

mark{
  background-color: rgb(207, 168, 243);
}

.abhisek-img
{
  width:50px;
  height:50px;
  object-fit: cover;
}

.insta-testimonial-icon a 
{
  color:rgb(69, 165, 255);
  text-decoration: none;
}
 /* Testomonial CSS End */

 /* Accordion CSS start */

 /* .course-heading
 {
  padding-left:40px;
  margin:1px 0px;
 } */

 .course-heading
{
  padding:10px 40px;
}

@media (max-width:767px)
{
  .course-heading
  {
    padding:10px 10px 1px;
  }
}
 
.accordion-button.collapsed {
  background: rgb(255, 255, 255);
  color:rgb(0, 0, 0);
}

.accordion-button.collapsed::after {
  background-image: url('./Images/plus-icon-13062.png') !important;
}

.accordion-button:not(.collapsed) {
  color:rgb(255, 255, 255) !important;
  background-color:rgb(0, 0, 0) !important;
  box-shadow:1px 1px 1px white !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color:rgb(149, 148, 148) !important;
  outline: 0 !important;
  box-shadow: 0px 0px 3px rgb(134, 133, 133) !important;
  -webkit-box-shadow: 0px 0px 3px rgb(145, 144, 144) !important;
}


.accordion-button:not(.collapsed)::after {
  background-image:url('./Images/close-button-png-30221.png') !important;
}

/* Accordion CSS End */

/* Query Alert Box CSS Start */

.query_inp:focus , .sel:focus
{
  outline: none !important;
  border: 1px solid rgb(69, 165, 255) !important;
}


/* Query Alert Box CSS End */

/* About US PAge css Start */

.about-desc
{
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.about-bg
{
  background-image: url(https://www.workinsync.io/wp-content/uploads/2022/06/Thumbnail-Small.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

/* About US PAge css End */

.masterclass-text
{
  color:transparent;
  -webkit-background-clip:text;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  font-weight: 900;
}

/* .imgCard:hover{
  box-shadow: 0, 0, 15px 0 rgba(0,0,0,0.4);
} */

.img:hover{
  /* box-shadow: 0 0px 18px 0 rgba(0,0,0,0.4); */
  /* width: 100%; */
}

.img1{
  /* box-shadow: 0 0px 18px 0 rgba(0,0,0,0.4); */
  width: 100%;
  height: 100%;
}

.imgCard:hover{
  background-color: rgb(69, 165, 255);
  box-shadow: 0 0px 18px 0 rgba(0,0,0,0.4);
  color: white !important;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
  padding: 20px 40px;
  box-shadow: 0, 0, 15px 0 rgba(0,0,0,0.4);
}

.filters {
  margin-right: 20px;
  width: 300px;
}

.filters h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
}

.card h3 {
  font-size: 16px;
  margin-top: 0;
}

.card label {
  display: block;
  margin: 10px 0;
}

.card input[type='radio'] {
  margin-right: 10px;
}

.internship-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.internship-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  margin: 10px;
}

.internship-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.internship-card h3 {
  font-size: 18px;
  margin-top: 0;
}

.internship-card p {
  font-size: 14px;
  margin-bottom: 10px;
}

.internship-card .logo {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.internship-card .logo img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.internship-card .logo span {
  font-size: 16px;
}

.card img{
  width: 30px;
  /* height: 50px; */
}

.container select{
  width: 100%;
  height: 40px;
  border: 1.8px solid black;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: white;
}

.filters button, .card button{
  padding: 10px 40px;
  border-radius: 8px;
  border: 2px solid rgb(69, 165, 255);
  background-color: rgb(69, 165, 255);
  color: white;
  font-weight: 500;
  transition-duration: 80ms;
}

.filters label{
  font-weight: 500;
  margin-bottom: 5px;
}

.filters button:hover, .card button:hover{
  background-color: white;
  color: rgb(69, 165, 255);
}

.card{
  position: relative;
  box-shadow: 0, 0, 15px 0 rgba(0,0,0,0.4);
}

.inline{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inline img{
  margin: 10px;
}

.inline h3{
  font-weight: 500;
  font-size: 18px;
}

.card button {
  width: fit-content;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition-duration: 50ms;
}

.podcast-btn{
  transition-duration: 80ms;
}

.podcast-btn:hover {
  background: white !important;
  color: rgb(42, 98, 157) !important
}

.bgMenu{
  /* background-color: #4158D0 !important; */
}

.bgItem:hover {
  background-color: #4158D0 !important;
  color: white !important;
}

@media screen and (max-width: 500px) { 
  .podcast {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 2.5rem;
  }
  .img-container-pod{
    margin-bottom: 1.1rem;
    margin-left: 1.3rem !important;
    /* margin-right: 1rem; */
  }
}


.about-founder {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 50px 0px;
  padding-bottom: 0;
  text-align: center;
}

.about-founder h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}

@media screen and (min-width: 600px) {
  .about-founder h2{
    text-align: right;
    margin-right: 50px;
  }
  .founder-info{
    text-align: right;
  }
}

@media screen and (max-width: 400px) {
  .founder-info{
    display: flex;
    flex-direction: column;
  }
  .founder-story div{
    display: flex;
    flex-direction: column-reverse;
  }
  .founder-story div img{
    max-width: 100vw;
    align-self: center;
  }
  .founder-awards{
    flex-direction: column;
  }
  .founder-awards div div{
    flex-direction: column;
    padding-left: 0 !important;
  }
  .founder-awards div div img{
    max-width: 100vw;
    margin-left: 0;
  }
}

.founder-info {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 30px;
}

.founder-info img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  /* border-radius: 100px; */
  margin-right: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.founder-info p {
  font-size: 18px;
  line-height: 1.5;
}

.founder-story h2{
  margin-top: 50px !important;
}

.founder-awards{
  margin-top: 70px;
}

.founder-story {
  background-color: rgba(68, 177, 255, 0.2);
  padding: 5px 0;
  padding-bottom: 50px;
}

.founder-story h3,
.founder-awards h3,
.founder-conclusion h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.founder-story p,
.founder-awards ul,
.founder-conclusion p {
  padding: 0 30px;
  font-size: 18px;
  line-height: 1.5;
}

.founder-conclusion p{
  padding: 0 40px;
}

.founder-awards ul {
  list-style: none;
  margin: 0;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.founder-awards li {
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.5;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.founder-conclusion {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #ddd;
}
